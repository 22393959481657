import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
//import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';
import { MessageService, SharedModule } from 'primeng/api';
//import { CaptchaModule } from 'primeng/captcha'; //appears not to be in this version?
import { DialogService } from 'primeng/dynamicdialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer-component/footer.component';
import { HeaderComponent } from './components/header-component/header.component';
import { ForecastComponent } from './routes/forecast/forecast.component';
import { COMMON_PRIME_NG_IMPORTS } from './common/common-imports';
import { SharedRailPerformanceModule } from './modules/shared-rail-performance/shared-rail-performance.module';
import { DashboardRoutingModule } from './modules/dashboard/dashboard-routing.module';
import { environment } from '../environments/environment';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { provideQueryDevTools } from '@ngneat/query-devtools';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularDateHttpInterceptor } from './interceptors/date.interceptor';
import { LandingComponent } from './modules/landing/landing.component';

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ...COMMON_PRIME_NG_IMPORTS,
    SharedModule,
    SharedRailPerformanceModule,
    DashboardModule,
    FontAwesomeModule
  ],
  exports: [
  ],
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    ForecastComponent,
    LandingComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    //{ provide: APP_BASE_HREF, useValue: '/#' }, 
    MessageService, //primeng
    DialogService, //primeng
    environment.production ? [] : provideQueryDevTools(),
    { provide: HTTP_INTERCEPTORS, useClass: AngularDateHttpInterceptor, multi: true }
    //  LoginService,
    //  ConfigService,
    //  MixpanelService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
