import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-component',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  get currentUser(): any {
    return undefined;
  }
}
