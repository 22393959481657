<div class="d-flex flex-column landing-container">
  <div class="d-flex flex-column position-relative">
    <img class="img-fluid welcome-image" src="/assets/images/home_background_hero.png">
    <div class="welcome-text position-absolute align-self-center" *ngIf="!!currentUser">WELCOME, {{currentUser.firstName}}</div>
    <div class="welcome-text position-absolute align-self-center" *ngIf="!currentUser">WELCOME</div>
    <div class="content-width dashboard-cards position-absolute">
      <div class="daas-card-box">
        <a routerLink="/dashboard">
          <div class="daas-card">
            <div class="d-flex flex-column align-items-center h-100">
              <svg width="131" height="130" viewBox="0 0 131 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.27 88.4998V33.0298" stroke="#7A939C" stroke-width="4" stroke-miterlimit="10"
                  stroke-linecap="round" />
                <path
                  d="M30.27 72.9023H59.495C60.265 72.9023 60.89 73.5348 60.89 74.3148V81.4473C60.89 82.2273 60.265 82.8598 59.495 82.8598H30.27"
                  stroke="#7A939C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                  d="M30.27 55.8047H52.5025C53.0875 55.8047 53.5625 56.4372 53.5625 57.2172V64.3497C53.5625 65.1297 53.0875 65.7622 52.5025 65.7622H30.27"
                  stroke="#7A939C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                  d="M30.27 38.7075H47.2175C47.665 38.7075 48.025 39.34 48.025 40.12V47.2525C48.025 48.0325 47.6625 48.665 47.2175 48.665H30.27"
                  stroke="#7A939C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                <path d="M46.4326 107.25H83.9326" stroke="#7A939C" stroke-width="4" stroke-miterlimit="10"
                  stroke-linecap="round" />
                <path d="M65.1826 95.7124V105.295" stroke="#7A939C" stroke-width="4" stroke-miterlimit="10"
                  stroke-linecap="round" />
                <path
                  d="M105.933 26H24.4326C22.7758 26 21.4326 27.3431 21.4326 29V92.7125C21.4326 94.3694 22.7758 95.7125 24.4326 95.7125H105.933C107.589 95.7125 108.933 94.3694 108.933 92.7125V29C108.933 27.3431 107.589 26 105.933 26Z"
                  stroke="#7A939C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                <path
                  d="M83.0427 65.1777C91.5506 65.1777 98.4477 58.2806 98.4477 49.7727C98.4477 41.2647 91.5506 34.3677 83.0427 34.3677C74.5348 34.3677 67.6377 41.2647 67.6377 49.7727C67.6377 58.2806 74.5348 65.1777 83.0427 65.1777Z"
                  stroke="#7A939C" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
                <path d="M67.6377 49.7725H83.0427V65.1775" stroke="#7A939C" stroke-width="4" stroke-miterlimit="10"
                  stroke-linecap="round" />
                <path d="M70.4927 86.2523L81.9752 76.3923L87.0577 82.2498L99.0652 71.4473" stroke="#7A939C"
                  stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" />
              </svg>
              <span class="card-title mt-4">Rail Network Performance</span>
              <span class="card-text mt-2">Boost your logistics and unleash the power of your rail system.</span>
              <button class="btn btn-action-primary my-3">Launch</button>
            </div>
          </div>
        </a>
        <a>
          <div class="daas-card">
            <div class="d-flex flex-column align-items-center h-100">
              <svg width="131" height="130" viewBox="0 0 131 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M98.375 101V65H74.375V101H62.375V53H38.375V101H29.375V23H23.375V101C23.375 102.591 24.0071 104.117 25.1324 105.243C26.2576 106.368 27.7837 107 29.375 107H107.375V101H98.375ZM56.375 101H44.375V59H56.375V101ZM92.375 101H80.375V71H92.375V101Z"
                  fill="#7A939C" />
                <path
                  d="M85.7329 58.9997C84.4599 58.999 83.2202 58.5935 82.1929 57.8417L50.9899 34.9607L42.1009 46.9997L37.2859 43.4177L46.2319 31.4177C47.163 30.1666 48.5448 29.3272 50.0842 29.0775C51.6237 28.8277 53.1999 29.1872 54.4789 30.0797L85.7419 52.9997L96.5569 38.4197L101.375 41.9897L90.5629 56.5757C90.0035 57.3294 89.2751 57.9413 88.4362 58.3623C87.5974 58.7833 86.6715 59.0016 85.7329 58.9997Z"
                  fill="#7A939C" />
              </svg>
              <span class="card-title mt-4">Forecast & Demand</span>
              <span class="card-text mt-2">Manage rail forecasts while tracking shipment performance and accuracy.</span>
              <span class="muted coming-soon my-3">Coming Soon</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>