import { Injectable } from '@angular/core';
import { CommodityDataService } from './commodity-data.service';
import { CustomerDataService } from './customer-data.service';
import { LocationDataService } from './location-data.service';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrefetchService {
  constructor(private commodityDataService: CommodityDataService,
              private customerDataService: CustomerDataService,
              private locationDataService: LocationDataService
  ) { }

  prefetch() {
    return combineLatest([
      this.commodityDataService.prefetch(),
      this.customerDataService.prefetch(),
      this.locationDataService.prefetch()
    ]);
  }
}
