import { Component, OnDestroy } from '@angular/core';
import { PrefetchService } from './modules/shared-rail-performance/services/prefetch.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnDestroy {
  private sub = new SubSink();

  constructor(private prefetchService: PrefetchService) {
    this.sub.sink = this.prefetchService.prefetch().subscribe();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  
  title = 'Rail Performance';
}
