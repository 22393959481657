import { Component, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { LOGIN_PAGES } from '../../routes/login-process/enum-login-pages';

//import { LoginModalComponent } from '../login-modal/login-modal.component';
//import { ConfigService } from '../../services/config-service/config.service';
//import { LoginService } from '../../services/login-service/login.service';
//import { MixpanelService } from '../../services/mixpanel-service/mixpanel.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  showLogin: boolean = false;

  loggedIn = false;
  user: any = null;

  public idleState = 'NOT_STARTED';
  public lastPing?: Date = new Date();
  public userSubs$ = new Subject();
  public subscription!: Subscription;
  public openMenuModal = false;

  constructor(public dialogService: DialogService, private route: Router) {
  }

  openLogin() {
    if (this.showLogin) {
      this.showLogin = false;
    } 
    else {
      this.showLogin = true;
    }
  }

  closeLogin(e: Event) {
    if (e.target != e.currentTarget) {
      return;
    }

    if (this.showLogin) {
      this.showLogin = false;
    } 
    else {
      this.showLogin = true;
    }
  }
}
