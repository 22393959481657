import { makeEnvironmentProviders, ENVIRONMENT_INITIALIZER, inject, DestroyRef } from '@angular/core';
import { injectQueryClient } from '@ngneat/query';

function provideQueryDevTools(devToolOptions = {}) {
    return makeEnvironmentProviders([
        {
            provide: ENVIRONMENT_INITIALIZER,
            multi: true,
            useValue() {
                const queryClient = injectQueryClient();
                const destroyRef = inject(DestroyRef);
                import('./ngneat-query-devtools-devtools-DlJEloyr.mjs').then((m) => {
                    m.queryDevtools({ queryClient, destroyRef, ...devToolOptions });
                });
            },
        },
    ]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { provideQueryDevTools };

