import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForecastComponent } from './routes/forecast/forecast.component';
import { LandingComponent } from './modules/landing/landing.component';

const routes: Routes = [
  { path: "", component: LandingComponent },
  { path: "home", component: LandingComponent },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  { path: "forecast", component: ForecastComponent },
  { path: "**", redirectTo: "home" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true, paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
